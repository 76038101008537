import classNames from 'classnames';
import classes from './twoPlayersIntroduction.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function FourPlayersIntroduction( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"Local Multiplayer Games for 2 Players? Rekindle the Flame with PlayCloud!!"}>
              <div className={classes.wrapper}>
              <h2>Ditch the Hassle, Embrace the Fun: PlayCloud Makes Two-Player Gaming a Breeze</h2>
    <p>Missing the thrill of battling it out or teaming up with a friend? PlayCloud reignites the magic of local multiplayer gaming! It's a revolutionary virtual gaming console that transforms your smartphone into a powerful controller. Simply connect to the PlayCloud app and you're ready to unleash epic fun for two!</p>
    <h2>Plug and Play, Game On! Seamless Setup for Instant Entertainment</h2>
    <p>Forget complex setups and waiting times. PlayCloud seamlessly connects to your TV, allowing you to jump into epic gaming sessions with your partner in seconds. Effortlessly transition from setup to pure gaming fun and create lasting memories with PlayCloud's two-player experience.</p>
    <h2>The Perfect Platform for Unforgettable Two-Player Adventures</h2>
    <h2>Thrilling Competition</h2>
    <p>Challenge your friend in fast-paced racing games, Hone your skills, strategize, and emerge victorious! Test your reflexes and see who reigns supreme.</p>
    <h2>Puzzle Perfect Duos</h2>
    <p>Test your problem-solving skills together and Work collaboratively (or maybe a little against each other) to solve mind-bending puzzles! Laughter and teamwork are guaranteed with these fantastic two-player games.</p>
    <h2>Beyond Convenience: The PlayCloud Advantage</h2>
    <p>PlayCloud offers more than just ease of use. Here's what makes it the ultimate two-player gaming platform:</p>
    <ul>
      <li>**Cost-Effective:** Ditch the need for multiple controllers and consoles. PlayCloud provides a budget-friendly way to enjoy local multiplayer games.</li>
      <li>**Always Up-to-Date:** Cloud-based gaming ensures you always have access to the latest games and updates. No more dusty cartridges or bulky discs!</li>
      <li>**Massive Game Library:** Explore a vast library of two-player games, with new titles constantly being added. There's always something new to discover and conquer as a duo!</li>
    </ul>
    <h2>Reignite the Joy of Two-Player Gaming with PlayCloud</h2>
    <p>Looking to recapture the excitement of gaming with a friend? Look no further than PlayCloud. With its streamlined setup, seamless two-player experience, and ever-expanding game library, PlayCloud is the perfect platform to create unforgettable gaming memories. Download the PlayCloud app today and unleash a new era of local multiplayer fun!</p>
</div>
      </ExplainerWrapper>


  );
}

export default FourPlayersIntroduction;
