import classNames from 'classnames';
import classes from './introduction3.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function Introduction2Container( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - Console for gaming with your friends and family"}>
      <div className={classes.wrapper}>
      <h2>Introducing PlayCloud - the ultimate virtual console for online games to play with friends.</h2>
<p>With PlayCloud, you can enjoy console gaming with your friends and family without the need for expensive equipment or controllers. All you need is your phone, and you're ready to start playing.</p>
<p>PlayCloud is designed to be user-friendly and easy to set up. Simply download the app onto your phone, connect it to your TV, and start playing online games with your friends. The app is intuitive and easy to navigate, so you can quickly find the online games you want to play with your friends.</p>
<p>One of the best things about PlayCloud is the wide variety of online games available to play with your friends. From classic arcade-style games to immersive multiplayer adventures, there's something for everyone. And because the console is cloud-based, you won't have to worry about downloading updates or running out of storage space on your device.</p>
<p>The online games on PlayCloud are designed to be fun and challenging, with plenty of replay value. Whether you're looking for a quick pick-up-and-play game or a longer, more immersive experience, you'll find it on PlayCloud. And because the console is constantly updating with new online games to play with your friends, you'll always have something new and exciting to play.</p>
<p>PlayCloud is also incredibly social. Playing online games with friends and family is a great way to bond and have fun together. And because the console is so accessible and easy to use, anyone can join in the fun, regardless of their age or gaming experience.</p>
<p>With PlayCloud, you can enjoy online games to play with friends in a whole new way. So why wait? Download the app today and start playing online games with your friends and family. With PlayCloud, the possibilities are endless!</p>
      </div>

      </ExplainerWrapper>


  );
}

export default Introduction2Container;
