import { v4 as uuidv4 } from 'uuid';

// or with require() syntax:
  const mixpanel = require('mixpanel-browser');
 export const initMixpanel = () => {
    const script = document.createElement("script");
    script.async = true
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1189369488359090";
    script.async = true;
    script.crossOrigin = 'anonymous'
  
    document.body.appendChild(script);

  let userId = localStorage.getItem('userId')
  if(userId == null) {
    userId = uuidv4()
    localStorage.setItem('userId', userId as string)
  }
// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
 mixpanel.init('369992d66a88ff2a8bc46af32a21ae00', { track_pageview: true, persistence: 'localStorage'});
 mixpanel.identify(userId)
}
export const  setCookie = (name: string,value: string,days: number) => {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  //@ts-ignore
  if (parts.length === 2) return parts.pop().split(';').shift();
}
if(!!getCookie('cookieConsent')) {
  initMixpanel()
}

export const reportEvent = (eventName: string, params?: any) => {
  try {
    const param = params ? {...params} : {}
    const query = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop: string) => searchParams.get(prop),
      });
      // @ts-ignore
      const from = query?.from
      if(from === "chen") {
        return
      }
    //@ts-ignore
    window.gtag('event', "client_" + eventName, { from, ...param});
    mixpanel.track("client_" + eventName, { from, ...param});
  } catch (e) {
    console.log("failed to send analytic")
  }

}