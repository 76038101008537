export const partyGamesPath = '/playcloudpartygames'
export const partyGamesSimilarPath = '/partygamessimilartomarioparty'
export const partyGamesSimilarPath2 = '/playcloudpartygamessimilar'
export const partyGameSpasnishPath = '/playcloudpartyjuegoscomo'
export const iceHockeyPath = '/icehockeylocalmultiplayergame'
export const kartGamePath = '/kartAlternative'
export const gameArticles  = [
    partyGamesPath,
    partyGamesSimilarPath,
    partyGameSpasnishPath,
    iceHockeyPath,
    kartGamePath
]
export const GameArticleLinks = () => {
    return <>
        {gameArticles.map(article=> <a  style={{width: 0, height: 0}} href={"https://www.play-cloud.com" + article} />)}
    </>
}