import React, { useContext, useEffect, useState } from 'react';
import classes from './adminLogs.module.scss'
import { useQuery } from '../../hooks/useQuery';
import { getAdminDataRequest } from '../../httpRequests/consoleRequests';
import IAdmin, { ILobby } from '../../interfaces/admin';
import { CONSOLE_URL, CONSOLE_URL_EU, CONSOLE_URL_US } from '../../consts/config';
import { AdminDataVM } from '../admin/admin';

interface AdminDataLogsVM  {
  onlinePlayers: {
    eu?: number,
    us?: number,
  }
  activeLobbys: {
    eu?: number,
    us?: number,
  },
  activeGames: {
    eu?: number,
    us?: number,
  },
  lobbyData: Array< ILobby>
  history: Array< ILobby>

}
function AdminLogsContainer() {
  const [adminData, setAdminData] = useState<AdminDataLogsVM
  | null>(null)
  const query = useQuery()
  useEffect(() => {
      // @ts-ignore
    getAdminData( query.admin)
  }, [])
   const getAdminData = async (admin : string) => {
    const adminDataEu = await getAdminDataRequest(admin, CONSOLE_URL_EU)
    const adminDataUs = await getAdminDataRequest(admin, CONSOLE_URL_US)
    setAdminData({
      onlinePlayers: {
        us: adminDataUs.onlinePlayers,
        eu: adminDataEu.onlinePlayers,
      },
      activeLobbys:{
        us: adminDataUs.activeLobbys,
        eu: adminDataEu.activeLobbys,
      },
      activeGames:{
        us: adminDataUs.activeGames,
        eu: adminDataEu.activeGames,
      },
      lobbyData: [
        ...adminDataEu.lobbyData.map((data: ILobby) => ({
          ...data,
          region: 'eu'
        })),
        ...adminDataUs.lobbyData.map((data: ILobby) => ({
          ...data,
          region: 'us'
        }))
      ],
      history: [
        ...adminDataEu.history.map((data: ILobby) => ({
          ...data,
          region: 'eu'
        })),
        ...adminDataUs.history.map((data: ILobby) => ({
          ...data,
          region: 'us'
        }))
      ]

    })
   }

   if(!adminData) {
     return null
   }

   //@ts-ignore
   const roomNumber =query.roomNumber
      //@ts-ignore
      const region =query.region
      //@ts-ignore
  const lobby = query.history === "true"
  ? adminData.history.find(lobby => lobby.roomNumber === roomNumber && region.toLowerCase() === lobby.region.toLowerCase()) 
  : adminData.lobbyData.find(lobby => lobby.roomNumber === roomNumber && region.toLowerCase() === lobby.region.toLowerCase()) 
  if(!lobby) {
    return null
  }
  return (
    <div className={ classes.wrapper}>
      {
        lobby.logs.map((log,index) => <div className={classes.log}>
            <div className={classes.number}> {index}.</div> {log}
          </div>)
      }
  </div>  
    
  );
}

export default AdminLogsContainer;
