import classNames from 'classnames';
import classes from './introduction4.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function Introduction2Container( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - virtual console for local multiplayer gaming"}>
      <div className={classes.wrapper}>
      <h1>Introducing PlayCloud - The Ultimate Virtual Console for Local Multiplayer Gaming</h1>
<p>Looking for an innovative way to play games with your friends without breaking the bank? Introducing PlayCloud – the ultimate virtual console for local multiplayer gaming. With PlayCloud, you can enjoy a wide range of games without having to invest in expensive equipment or controllers. All you need is your phone, and you're ready to start playing.</p>
<p>PlayCloud is designed to be user-friendly and easy to set up. Simply download the app onto your phone, connect it to your TV, and start playing. The app is intuitive and easy to navigate, so you can quickly find the games you want to play.</p>
<p>One of the best things about PlayCloud is the wide variety of games available. From classic arcade-style games to immersive multiplayer adventures, there's something for everyone. And because the console is cloud-based, you won't have to worry about downloading updates or running out of storage space on your device.</p>
<p>The games on PlayCloud are designed to be fun and challenging, with plenty of replay value. Whether you're looking for a quick pick-up-and-play game or a longer, more immersive experience, you'll find it on PlayCloud. And because the console is constantly updating with new games, you'll always have something new and exciting to play with your friends.</p>
<p>PlayCloud is also incredibly social. Playing games with friends and family is a great way to bond and have fun together. And because the console is so accessible and easy to use, anyone can join in the fun, regardless of their age or gaming experience.</p>
<p>With PlayCloud, you can enjoy a wide variety of games that you can play with friends. So why wait? Download PlayCloud today and start playing with your friends and family. With PlayCloud, the possibilities for games you can play with friends are endless!</p>
      </div>

      </ExplainerWrapper>


  );
}

export default Introduction2Container;
