
import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import xIcon from '../../assets/images/xIcon.png'
import classes from './addPeopleExplainer.module.scss'
import { translations } from '../../i18n/config';
import { getTranslation } from '../../utils/anyUtils';
import classNames from 'classnames';
import googlePlayIcon from '../../assets/images/googlePlayIcon2.png';
import appStoreIcon from '../../assets/images/appStoreIcon.png';
import controllerGif from '../../assets/images/controllerGif.gif';
const AddPeopleExplainer = ({qrUrl, unclickable = false,transparentBackground, bottom, onClose, roomNumber}: {unclickable?: boolean, qrUrl: string,transparentBackground?: boolean, bottom?: boolean,onClose?: () => void, roomNumber: string}) => {

    const t = getTranslation("games")
    return (
      <div className={classNames(classes.explainerContainerWrapper, {[classes.transparent]: transparentBackground, [classes.bottom]: bottom, [classes.unclickable]: unclickable})}>
        <div className={classes.explainerContainer}>
          <div className={classes.topContainer}>
            <div className={classes.explainerWhy}>{t.explainController}</div>
              <img className={classes.gif} src={controllerGif} />

            </div>
          <div className={classes.bottomContainer}>
          <div>
            <div className={ classes.qr }>
            <QRCode className={classes.qr2} size={256} value={qrUrl}/>
            </div> 
          </div>
          <div className={ classes.or }>
          </div> 
          <div className={classes.otherExplainer}>
            
          <div className={ classes.block }>
            <div className={ classes.explainer }>
              1. {t.explainer} {/*<img className={classes.icon} src={appStoreIcon} /><img className={classes.icon} src={googlePlayIcon}/>*/}
            </div>
          </div>
          <div className={ classes.explainer }>
              2. {t.explainer2}
            </div>

          <div className={ classes.roomNumber }>
            {roomNumber}
          </div>
          <div className={ classes.explainer }>
              {t.explainer3}
            </div>
          </div>
          </div>

        { onClose && <img onClick={ onClose } src={ xIcon } className={classes.closeIcon} /> }


        </div>
      </div>
    )
  }

  export default AddPeopleExplainer