import classNames from 'classnames';
import classes from './introduction.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function IntroductionContainer( ) {
  
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - The ultimate virtual console for local multiplayer gaming"}>
        <div className={classes.wrapper}>
        <div >
          <h1>Introducing PlayCloud</h1>
          <p>The ultimate virtual console for local multiplayer gaming. With PlayCloud, you can enjoy console gaming with your friends and family without the need for expensive equipment or controllers. All you need is your phone, and you're ready to start playing.</p>
          <h2>Easy to Set Up and User-Friendly</h2>
          <p>PlayCloud is designed to be user-friendly and easy to set up. Simply download the app onto your phone, connect it to your TV, and start playing. The app is intuitive and easy to navigate, so you can quickly find the games you want to play.</p>
          <h2>A Wide Variety of Games</h2>
          <p>One of the best things about PlayCloud is the wide variety of games available. From classic arcade-style games to immersive multiplayer adventures, there's something for everyone. And because the console is cloud-based, you won't have to worry about downloading updates or running out of storage space on your device.</p>
          <h2>Fun and Challenging Games</h2>
          <p>The games on PlayCloud are designed to be fun and challenging, with plenty of replay value. Whether you're looking for a quick pick-up-and-play game or a longer, more immersive experience, you'll find it on PlayCloud. And because the console is constantly updating with new games, you'll always have something new and exciting to play.</p>
          <h2>Social Gaming</h2>
          <p>PlayCloud is also incredibly social. Playing games with friends and family is a great way to bond and have fun together. And because the console is so accessible and easy to use, anyone can join in the fun, regardless of their age or gaming experience.</p>
          <h2>Endless Possibilities</h2>
          <p>With PlayCloud, you can enjoy local multiplayer gaming in a whole new way. So why wait? Download PlayCloud today and start playing with your friends and family. With PlayCloud, the possibilities are endless!</p> 
      
      </div>
        </div>

      </ExplainerWrapper>


  );
}

export default IntroductionContainer;
