import React, { useState, useContext, useEffect, useCallback, useRef, ReactNode } from 'react';

import { getGamesRequest, postAnalytics } from '../../httpRequests/consoleRequests';
import IGame from '../../interfaces/game';
import classes from './gamesHiveBackground.module.scss'

function GamesHiveBackground( { onGameClicked, children}: {onGameClicked: (game: IGame) => void, children: ReactNode}) {
    const [games, setGames] = useState<Array<IGame>>([])
    useEffect(() => {
        getAvailableGames()
    }, [])
    const handleGameClicked = (game: IGame) => {
        onGameClicked(game)
    }
    const getAvailableGames = async () => {
        const rolloutStorage = localStorage.getItem('rollout')

        const data : Array<IGame> = await getGamesRequest()
        const filteredGames = data.filter(game => rolloutStorage === "testing" ? true : game.rollout === 'production')
        setGames(filteredGames)
      }
      const GameGif = ({index, gameIndex}:{index: number, gameIndex: number}) => {
        return (
            <img style={{animationDelay: (0.1 * index).toString() + 's'}} onClick={() => handleGameClicked(games[gameIndex])} className={classes.gif} src={games[gameIndex].gif} />
        )
    }
  return (
    <>
               {
            games.length > 3 && 
            <div className={classes.gifRowsRight}>
                <div className={classes.gifRowRight}>
                    <GameGif index={1} gameIndex={3}/>
                    <GameGif index={0} gameIndex={1}/>
                </div>
                <div className={classes.gifRowRight}>
                    <GameGif index={4} gameIndex={5}/>
                    <GameGif index={3} gameIndex={0}/>
                    <GameGif index={2} gameIndex={2}/>
                </div>
                <div className={classes.gifRowRight}>
                    <GameGif index={6} gameIndex={4}/>
                    <GameGif index={5} gameIndex={6}/>
                </div>
            </div>
        }
        {
            games.length >= 3 &&
            <div className={classes.gifRowsLeft}>
                <div className={classes.gifRowLeft}>
                    <GameGif index={0} gameIndex={7}/>
                </div>
                <div className={classes.gifRowLeft}>
                    <GameGif index={2} gameIndex={4}/>
                    <GameGif index={1} gameIndex={1}/>

                </div>
                <div className={classes.gifRowLeft}>
                    <GameGif index={3} gameIndex={2}/>
                </div>
            </div>
        }
        {children}
    </>
  );
}

export default GamesHiveBackground;
