import { translations } from '../i18n/config';

export const isIOS = ()  => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  export const getTranslation = (context: string) => {
    const language = getLanguage()
    //@ts-ignore
    const translation = translations[language] ? translations[language] : translations['en'];
    //@ts-ignore
    const t = translation[context]
    return t
  }
  export const getLanguage = () => {
    const language = navigator.language.split("-")[0] || "en"
    return localStorage.getItem("lang") === null ?  language : localStorage.getItem("lang")
  }
  export function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout
    return (...args: Params) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, timeout)
    }
  }