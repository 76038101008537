
import React, { ReactNode, useEffect } from 'react';
import classes from './explainerWrapper.module.scss'
import logoImg from '../../assets/images/logoImg.png';
import explainerImg from '../../assets/images/hardsPlaying.png'
import ReactPlayer from 'react-player';
import { postAnalytics } from '../../httpRequests/consoleRequests';
import { debounce } from '../../utils/anyUtils';
import GamesHiveBackground from '../gameshivebackground';
import IGame from '../../interfaces/game';
import { ArticleLinks } from '../../consts/articles';
import { GameArticleLinks } from '../../consts/gameArticles';
const ExplainerWrapper = ({customVideo, children, title}: {customVideo?:string, children: ReactNode, title: string}) => {
    const debouncedReportEvent = debounce( () => postAnalytics("introduction_shown", {link: window.location.href, referrer: document.referrer}), 200)
    useEffect(() => { 
        document.title =title
        debouncedReportEvent()
    }, [])
    const handleClick = () => {
        postAnalytics("introduction_btn_clicked", {link: window.location.href, referrer: document.referrer})
    }
    const handleGameClicked = async (game: IGame) => {
        await postAnalytics("introduction_btn_clicked", {link: window.location.href, referrer: document.referrer})
        await postAnalytics('host_clicked_on_specific_game', {link:  window.location.href, gameKey: game.key})
        window.location.href = ( "/" + game.path)
    }
    return (<GamesHiveBackground onGameClicked={handleGameClicked}>
        <div className={classes.wrapper}>
        <div className={classes.explainerContainer}>
            <div className={ classes.titleContainerMobile }>
                <img src={ logoImg } className={ classes.logoImg } />
                <div className={ classes.title }>
                    PlayCloud
                </div>
            </div>
            <div className={ classes.gameDisplayMobile }>
                <div className={classes.scrollExplainer}>
                    Scroll down to read more
                </div>
                <div className={classes.gradient}/>
                <div className={classes.bottomIllustration}>
                    <div className={ classes.playerWrapperMobile}>
                        <ReactPlayer
                            className='react-player fixed-bottom'
                            url= {!customVideo ? `/videos/introductionVideo.mp4` : customVideo}
                            width='100%'
                            height='100%'
                            muted
                            loop
                            playing
                        />
                    </div>
                    <img src={ explainerImg } className={classes.explainerImgMobile}/>
                    </div>

                    <a href={'/'} onClick={ handleClick } className={classes.startBtn}>
                        START NOW FOR FREE
                    </a>
            </div>  
            <div className={classes.contentWrapper}>
                {children}
                <ArticleLinks/>
                <GameArticleLinks/>
            </div>
            
        </div>
    </div>
    </GamesHiveBackground>)

}
export default ExplainerWrapper