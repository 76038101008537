import classNames from 'classnames';
import classes from './introduction6.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function Introduction6Container( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - Multiplayer gaming platform"}>
      <div className={classes.wrapper}>
      <h1>Introducing PlayCloud - the Ultimate Virtual Console for Local Multiplayer Gaming</h1>
<p>Looking for an alternative to popular multiplayer platforms like AirConsole and Jackbox? You're sure to love PlayCloud!</p>
<p>With PlayCloud, you can enjoy console gaming with your friends and family without the need for expensive equipment or controllers. All you need is your phone, and you're ready to start playing. As a great alternative to other multiplayer gaming platforms, PlayCloud is user-friendly and easy to set up, so you can quickly connect it to your TV and start playing.</p>
<p>PlayCloud offers a wide variety of games that are perfect for playing with friends and family. Just like its alternatives, AirConsole and Jackbox, PlayCloud's games are designed to be fun and challenging, with plenty of replay value. Whether you're into classic arcade-style games or more immersive multiplayer adventures, you'll find something you love on PlayCloud. And because the console is cloud-based, you won't have to worry about downloading updates or running out of storage space on your device.</p>
<p>One of the best things about PlayCloud is its social aspect. Playing games with friends and family is a great way to bond and have fun together, just like on its alternatives, AirConsole and Jackbox. And because the console is so accessible and easy to use, anyone can join in the fun, regardless of their age or gaming experience.</p>
<p>As a great alternative to other multiplayer gaming platforms, PlayCloud is constantly updating with new games, so you'll always have something new and exciting to play. Whether you're looking for a quick pick-up-and-play game or a longer, more immersive experience, you'll find it on PlayCloud.</p>
<p>So why wait? Download PlayCloud today and try out an alternative option for local multiplayer gaming with your friends and family. With PlayCloud, the possibilities are endless!</p>
</div>
      </ExplainerWrapper>


  );
}

export default Introduction6Container;
