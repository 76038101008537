import classNames from 'classnames';
import React, { useState, useContext, useEffect, useCallback, useRef, ReactNode } from 'react';
import ReactPlayer from 'react-player';
import explainerImg from '../../assets/images/explainerImg.png'
import personIcon from './../../assets/images/personIcon.png'
import { useNavigate } from 'react-router-dom';
import { FeatureToggleContext, LobbyContext, SocketContext } from '../../App';
import { getGamesRequest, postAnalytics } from '../../httpRequests/consoleRequests';
import IGame from '../../interfaces/game';
import socketService from '../../services/socket.service';
import classes from './specificGame.module.scss'
import Logo from '../../components/logo/logo';
import QRCode from 'react-qr-code';
import AddPeopleExplainer from '../../components/addPeopleExplainer';
import { getLanguage, getTranslation } from '../../utils/anyUtils';
import { reportEvent } from '../../services/analytics.service';
import { useQuery } from '../../hooks/useQuery';
import logoImg from '../../assets/images/logoImg.png';
import Helmet from 'react-helmet'
import Explainer from '../launch/explainer';
import ExplainerWrapper from '../../components/explainerWrapper';
import { GameArticleLinks } from '../../consts/gameArticles';
import { ArticleLinks } from '../../consts/articles';
export const GAME_INTRODUCERS = [{
  key: 'bubblewar',
  introducer: <>
  <h1>Bubble War: A New Alternative to the Nostalgic Bubble Trouble Game</h1> 
  <p>Do you remember the classic arcade game <strong>Bubble Trouble</strong>? It was a simple yet addictive game where you had to pop all the bubbles on the screen with a harpoon.
   The game was created was created in 1997 and became a hit among gamers of all ages.
    Bubble Trouble had three versions, each with more levels and challenges than the previous one. You could also play with a friend in the two-player mode and cooperate or compete to pop the bubbles faster.</p>
     <p>Bubble Trouble was a game that tested your reflexes, timing, and strategy. You had to avoid getting hit by the bubbles or the spikes on the walls and ceiling. Bubble Trouble was a game that made you smile, laugh, and rage at the same time.</p> 
     <p><strong>Bubble War</strong>, is a new 3d game that you should definetly check out! Played with your phone as the controller in front of the TV.
      It lets you play 1 to 4 people in the same team or even a 1 vs 1 or 2 vs 2 mod with split screen.
     and adds new features and modes that make it even more enjoyable.</p>
     <p>Bubble War has stunning 3d graphics and sound effects that make the game more immersive</p>
     <h2>But what makes Bubble War truly unique is that you can play it with your phone as the controller in front of your TV</h2>
      <p>Yes, you heard that right. You can connect your phone with your phone’s camera to scan QR codes on your TV screen, Then it turns into a game controller. This way, you can enjoy Bubble War on a bigger screen and have more fun with your friends. ( UP TO 4 PEOPLE! )</p> 
      <h2>Bubble War is a game that will appeal to fans of Bubble Trouble and new players alike</h2> 
      <p>It is a game that combines nostalgia and innovation in a perfect balance. It is a game that will challenge your skills and entertain you for hours.Scan the QR code and start playing!</p>
       <p> Have fun popping bubbles!</p> </>
}]

function GamesContainer( { keywords, description, handle, introduction, title, predeterminedGameKey}: {keywords?: string,description?: string,handle: any,introduction?: any, title?: string, predeterminedGameKey?: string}) {
  console.log("des", description)
  const [selectedGame, setSelectedGame] = useState<IGame | undefined>(undefined)
  const lobbyContext = useContext(LobbyContext);
  const featureToggles = useContext(FeatureToggleContext);
  const navigate = useNavigate()
  const language = getLanguage()
  const t = getTranslation("games")
  // @ts-ignore
  const rolloutStorage = localStorage.getItem('rollout')
  const rollout = rolloutStorage == null ? 'production' : rolloutStorage
  const isLoading = !selectedGame
  const timeoutLobbyChange = useRef<NodeJS.Timeout>()
  const query = useQuery()
  
  let isStarting = false;
  // @ts-ignore
  const gameKey = predeterminedGameKey? predeterminedGameKey : (query && query.gameKey ? query.gameKey : 'testgamelocal')
  useEffect(() => {
    if(title) {
      document.title =title
    }

    getSelectedGame()
  }, [])
  
  const connectToLobby = () => {
    localStorage.removeItem("lastGamePlayed")

    socketService.emitSocketEvent('createLobby', {from: "test"})
}
  useEffect(() => {
    const filteredPlayers = lobbyContext.players.filter(player => !!player)
    if(filteredPlayers.length > 0 && !isStarting) {
      console.log("starting")
      postAnalytics('host_started_game_from_specific_game', {gameKey})
      isStarting = true
      handleStartGame()
    }
  }, [selectedGame,lobbyContext.players, lobbyContext.roomNumber])
  useEffect(() => {
    if(!socketService.socket) {
      return
    }
    connectToLobby()
    window.onpopstate = handleBackButton
    socketService.socket.emit("logToLobby", {message: "Loading specific game " + gameKey})
    return () => {

    }
  }, [ socketService.socket])
  useEffect(() => {
    if(lobbyContext.players && lobbyContext.players.length > 0 && lobbyContext.players[0] != null && !!lobbyContext.players[0].from){
      const fromFromLocalstorage = localStorage.getItem("from")
      if(localStorage.getItem("firstPlayerLogged") != "true" && fromFromLocalstorage?.includes('organic')) {
        if (localStorage.getItem("from") == null) {
          localStorage.setItem("firstPlayerLogged", "true")
          localStorage.setItem("from", lobbyContext.players[0].from!)   
        }
      }
    }

  }, [lobbyContext.players])

  const handleBackButton = () => {
    socketService.socket.emit("logToLobby", {message: "Host clicked back button"})
  }

  const getSelectedGame = async () => {
    const data : Array<IGame> = await getGamesRequest()
    const filteredGames = data.filter(game => rollout === "testing" ? true : game.rollout === 'production')
    const selectedGame = filteredGames.find(game => game.key === gameKey)
    
    setSelectedGame(selectedGame)
  }

  const from = localStorage.getItem("from")
  const transformedFrom = from ? from.replace("_qr_scan", "") : from 
  const handleStartGame= useCallback(() => {
    if(!selectedGame) return
    socketService.socket.emit("logToLobby", {message: "Host selected a game from a specific game : " + gameKey})
    reportEvent('host_game_selected')
    localStorage.setItem("loadingImage", selectedGame!.loadingImgUrl)
    socketService.emitSocketEvent("gameLaunched", {roomNumber: lobbyContext.roomNumber, gameKey: gameKey})
    if(lobbyContext.roomNumber && lobbyContext.players.filter(t => !!t).length > 0){
      navigate('/game?roomNumber=' + lobbyContext.roomNumber + "&from=" + from +"&gameKey=" + gameKey +"&host=" + socketService.address + "&language=" + language )
    }
  }, [selectedGame,lobbyContext.roomNumber, lobbyContext.players])


  const qrUrl = window.location.origin + "/browser?roomNumber=" + lobbyContext.roomNumber! + "&from=" + transformedFrom + "_qr_scan" + "&language=" + language

  const gameTranslations = getTranslation("gamekeys" + selectedGame?.key )
  const gameIntroducer = GAME_INTRODUCERS.find(introucer => introucer.key ===  selectedGame?.key)
  return (
    <>
      <ArticleLinks/>
      <GameArticleLinks/>
      <Helmet>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      </Helmet>
    {
      lobbyContext.roomNumber ?
<div className={ classes.wrapper }>
       {
          isLoading &&
        <div className={ classes.logoWrapperLoading }>

          <Logo/>
        </div>
        }
        <div className={ classes.logoWrapper }>
          <img src={ logoImg } className={ classes.logoImg} />
          <div className={ classes.text }>
            PlayCloud
          </div>
        </div>
      
        <div className={ classes.container }>
          <>
              <div className={ classes.gamesWrapper}>
          <div className={ classes.gamesContainer }>
            {
              selectedGame &&
              <div className={ classes.selectedGameDisplay }>

              <div className={ classes.selectedGameData }>
                <div className={ classes.details }>
                  <div className={ classes.gameName }>
                    {selectedGame.name} <img className={classes.gameImage} src={selectedGame.imgUrl} alt={title} />
                  </div>

                  <div className={ classes.participants }>
                    <img src={ personIcon } className={ classes.personIcon }/>
                    <div>
                      {selectedGame.minNumberOfPlayers} - {selectedGame.maxNumberOfPlayers}
                    </div>
                  </div>
                  <div className={ classes.description }>
                    {!!introduction ? introduction: (gameIntroducer ? gameIntroducer.introducer : (gameTranslations ? gameTranslations.description : undefined))}
                  </div>
                </div>


              </div>

              <div className={ classes.gameDisplay }>
                <div className={ classes.gradient} />
                <div className='player-wrapper'>
                <ReactPlayer
                playsinline
                    className='react-player fixed-bottom'
                    url= {`/videos/${gameKey}.mp4`}
                    width='100%'
                    height='100%'
                    loop
                    muted
                    playing
                    />
            </div>
              </div>
            </div>
            }
          <div className={ classes.bottomGradient} />
          </div>

          </div>
                {   
                !((lobbyContext && lobbyContext.players && lobbyContext.players.filter(p => !!p).length) > 0) &&
                <AddPeopleExplainer 
                bottom
                unclickable
                  transparentBackground
                  qrUrl={qrUrl}
                  roomNumber={ lobbyContext.roomNumber }/>
                }
              </>

        </div>
      
    </div> : <ExplainerWrapper title={title!} customVideo={`/videos/${gameKey}.mp4`}>
    <div className={ classes.wrapperMobile }>
      
    {!!introduction ? introduction: (gameIntroducer ? gameIntroducer.introducer : (gameTranslations ? gameTranslations.description : undefined))}
      </div>
    </ExplainerWrapper>
    }


    </>

  );
}

export default GamesContainer;
