import React, { useContext, useEffect, useState } from 'react';
import classes from './gameTopLayer.module.scss'
import { LobbyContext } from '../../App';
import enlargeIcon from '../../assets/images/enlargeIcon.png'
import minifyIcon from '../../assets/images/minifyIcon.png'
import logoImg from '../../assets/images/logoCropped.png';
import israelflag from '../../assets/images/israelflag.png';
import AddPeopleExplainer from '../addPeopleExplainer';
import socketService from '../../services/socket.service';
import classNames from 'classnames';
import { getLanguage, getTranslation } from '../../utils/anyUtils';

interface IProps {
  className?: string
  handle?: any
  gameMode?: boolean
}
enum EPingType {
  good = 'good',
  medium = 'medium',
  bad = 'bad',
  notPlayable = 'notPlayable'
}
function GameTopLayer({ handle, gameMode }: IProps) {
  const lobbyContext = useContext(LobbyContext);
  const [ping,setPing] = useState(0)
  const [isViewingQR, setIsViewingQR ] = useState(false)
  const language = getLanguage()
  //@ts-ignore
  const t = getTranslation('topLayer')
  const fontSizeMap = {
    0: 14,
    5: 11,
    8: 8,
  }
  useEffect(() => {
    checkPing()
    setInterval(checkPing, 5000)

    socketService.socket.on("addPlayersRequested", handleAddPlayer)
    return () => {
      socketService.socket.off("addPlayersRequested", handleAddPlayer)
    }
  }, [])

  const checkPing = () => {
    const start = Date.now();

    socketService.socket.emit("ping", () => {
      const duration = Date.now() - start;
      setPing(duration)
    });
  }
  const handleAddPlayer = () => {
    setIsViewingQR(is => !is)
  }
  const getFontSize =(text: string, map: object) : number =>   {
    let fontSize = 11
    Object.entries(map).forEach(([key, value]) =>{
      if(parseInt(key) <= text.length) {
        fontSize = value
      
      }
    })
    return fontSize;
  }
  const rgbaAsString = ({r,g,b,a}: {r:number, g: number, b: number, a: number}) => {
    return `rgba(${r},${g},${b},${a})`
  }
  const handleEnlargeOrMinifiy = () => {
    if(handle.active) {
      handle.exit()
    } else {
      handle.enter()
    }
  }

  const handleViewQrBig = () => {
    setIsViewingQR(true)
  }
  const handleSetCloseAddPeople = () => {
    setIsViewingQR(false)
  }
  const getPingType = () => {
    if(ping < 60) {
      return EPingType.good
    }
    if(ping < 100) {
      return EPingType.medium
    }
    if(ping < 130) {
      return EPingType.bad
    }
    return EPingType.notPlayable
  }
  
  const pingType = getPingType()
  const from = localStorage.getItem("from")
  const transformedFrom = from ? from.replace("_qr_scan", "") : from 
  const qrUrl = window.location.origin  + "/download?roomNumber=" + lobbyContext.roomNumber + "&from=" + transformedFrom + "_qr_scan" + "&language=" + language
  return (
    <div className={ classNames(classes.topLayer, {[classes.playMode]: handle.active && gameMode}) }>
      {
        lobbyContext && lobbyContext.roomNumber && isViewingQR &&
        <AddPeopleExplainer
          onClose={ handleSetCloseAddPeople }
          qrUrl={ qrUrl }
          roomNumber={lobbyContext.roomNumber!}
          />
      }
      <div className={classNames(classes.pingContainer, classes[pingType?.toString()])}>
        ping : {ping}
      </div>
      <div className={ classes.titleContainer }>
          <img src={ logoImg } className={ classes.logoImg } />
          <div className={ classes.title }>
              PlayCloud
          </div>
      </div>

      <div className={ classes.players }>
        <div  onClick={ handleViewQrBig } className={ classes.addPlayersContainer}>
          <div className={ classes.plus }>
            +
          </div>
          <div className={ classes.addPlayers }>
            {t.addPlayers}
          </div>
        </div>

      {
        lobbyContext && lobbyContext.players && lobbyContext.players.filter(player => !!player).map((player,index) => 
        <div style={{borderColor: player.color ? rgbaAsString(player.color) : undefined}} className={ classes.player }>
          <div style={{fontSize: getFontSize(player.name, fontSizeMap)}} className={ classes.playerText }>
            {player.name.substring(0, 20)}
          </div>
        </div>)
      }
    </div>
    { handle && <img src={ handle.active ? minifyIcon : enlargeIcon } onClick={ handleEnlargeOrMinifiy } className={classes.enlargeIcon} /> }
    {
      lobbyContext.roomNumber &&
      <div className={ classes.roomData }>
       <div className={ classes.roomNumber }>
       {lobbyContext.roomNumber}
      </div>
        
    </div>
    }
  </div>
  );
}

export default GameTopLayer;
