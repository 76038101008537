import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js'
import { isBrowser, isMobile } from 'react-device-detect';
import { io } from 'socket.io-client';

import { CONSOLE_URL } from '../consts/config';
import { getSocketServerAddress } from '../httpRequests/consoleRequests';
import { getCookie, setCookie } from './analytics.service';
dayjs.extend(utc)
function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
function isValidTimestamp(_timestamp: string) {
  const newTimestamp = new Date(_timestamp).getTime();
  return isNumeric(newTimestamp);
}
const currentDateStartedPlaying = localStorage.getItem("date_started_playing")
if(currentDateStartedPlaying == null) {
  localStorage.setItem("date_started_playing", dayjs().utc().unix().toString())

}  else {
  if(currentDateStartedPlaying === "l" ||currentDateStartedPlaying === "NaN" ) {
    localStorage.setItem("date_started_playing", dayjs().utc().unix().toString())

  } else {
    if((currentDateStartedPlaying!.match(/^[0-9]+$/) === null)) {
      localStorage.setItem("date_started_playing", dayjs(currentDateStartedPlaying!).utc().unix().toString())
    }
  }
}
/*
const currentDateStartedPlayingCookie = getCookie("date_started_playing")
const dateStartedPlayingLocalhost = localStorage.getItem("date_started_playing")
if(getCookie("cookieConsent") === "true" && getCookie("date_started_playing") == null) {
  if(dateStartedPlayingLocalhost !== null) {
    setCookie("date_started_playing", dateStartedPlayingLocalhost,1555555)
  } else {
    setCookie("date_started_playing", dayjs().utc().unix().toString(),1555555)
  }
} 

*/
export const getDateStartedPlaying = () => {
  const dateStartedPlayingLocalhost = localStorage.getItem("date_started_playing")
  return dateStartedPlayingLocalhost
}

  
export let serverAddress : undefined | string = undefined
const socketRoutes = ['/game', '/specificGame', '/games', '/', '/slipnbump','/icehockygame','/roperagegame', '/kartalternative','/ballfrenzygame', '/icehockeylocalmultiplayergame', '/slipnbump', '/partygames', '/racensmash','/playcloudpartyjuegoscomo','/playcloudpartygamessimilar','/partygamessimilartomarioparty','/playcloudpartygames', '/bubblewargame','/capturetheflag']
export const isSocketRoute = socketRoutes.includes(window?.location?.pathname) && ((window.matchMedia("(min-width: 768px)").matches && isMobile) || isBrowser)
const query = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  const referrerDomain = document.referrer === "" ? document.referrer : (document.referrer.includes('play-cloud') ? '' : document.referrer.split('/')[2])
  // @ts-ignore
  const from = query?.from  ? query?.from : (referrerDomain !== "" ? referrerDomain : 'direct' );
  const dateStartedPlaying = localStorage.getItem("date_started_playing")
  if (localStorage.getItem("from") == null) {
    localStorage.setItem("from", from)   
  }
  const fromFromLocalstorage = localStorage.getItem("from")
  // @ts-ignore
export const getServerData = async () => {
  const data = await getSocketServerAddress()
  serverAddress = data.address
  return data
}
export let socketID = '';
class SocketService {
  public socket : undefined | any = undefined 
  public address : undefined | string = undefined 
  constructor() {
  }
  emitSocketEvent = (event: string, params?: any) => {
    const param = params ? {...params} : {}
    const query = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop: string) => searchParams.get(prop),
      });
      // @ts-ignore
      const from = query?.from
    //@ts-ignore
    this.socket.emit(event, {from,dateStartedPlaying, ...param})
  }
  Setup(address: string ) {
    this.address = address
    //@ts-ignore
    this.socket = isSocketRoute
    ? io(this.address, { 
 //     path: this.address.split(".com")[1] + "/socket.io",     
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
          //@ts-ignore
      query: "from=" + fromFromLocalstorage + (!isMobile ? "&isHost=true&dateStartedPlaying=" + dateStartedPlaying: "")}) : {on: () => {}, off: () => {}, emit: ()=> {}}
  }
}
export default new SocketService()

