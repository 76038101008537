import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import { FeatureToggleContext, SocketContext } from '../../App';
import logoImg from '../../assets/images/logoImg.png';
import googlePlayIcon from '../../assets/images/googlePlayIcon2.png';
import patreonIcon from '../../assets/images/patreonLogo.png';
import appStoreIcon from '../../assets/images/appStoreIcon.png';
import invertedLogo from '../../assets/images/logoinvert4.png';
import { BrowserView, isIOS, isAndroid, isMobile, MobileView, isBrowser } from 'react-device-detect';

import Logo from '../../components/logo/logo';
import socketService, { getDateStartedPlaying } from '../../services/socket.service';
import classes from './launch.module.scss'
import explainerImg from '../../assets/images/hardsPlaying.png'
import imagesToPreload from './imagesToPreload';
import useImagePreloader from '../../hooks/useImagePreloader';
import { translations } from '../../i18n/config';
import Explainer from './explainer';
import { getLanguage, getTranslation } from '../../utils/anyUtils';
import { APP_STORE_URL, DEEP_LINK_ANDROID, PLAY_STORE_URL } from '../../consts/config';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ExplainerItem from '../../components/explainerItem';
import step1 from '../../assets/images/step1.png';
import qrStep from '../../assets/images/qrstep.png';
import tv from '../../assets/images/tv.png';
import gameIllustrator from '../../assets/images/game-illustrator.png';
import classNames from 'classnames';
import { reportEvent } from '../../services/analytics.service';
import ReactPlayer from 'react-player';
import { getGamesRequest, postAnalytics } from '../../httpRequests/consoleRequests';
import ReportBugModal from '../../components/reportBugModal';
import IGame from '../../interfaces/game';
import GamesHiveBackground from '../../components/gameshivebackground';
import { ArticleLinks } from '../../consts/articles';
import { GameArticleLinks } from '../../consts/gameArticles';
import { useQuery } from '../../hooks/useQuery';

const ExplainerContainer = ({ handle }: {handle: any}) => {
    const navigate = useNavigate()
    const from = localStorage.getItem("from")
    const t = getTranslation("launch")
    const query = useQuery()

    useEffect(() => {
        if(!socketService.socket) return
        //@ts-ignore
        const startImmediately = query && query.startImmediately === "true"
        socketService.socket.on('lobbyCreated',handleLobbyCreated)
        if(startImmediately) {
            handleStartALobby()
        }
        return () => {
            socketService.socket.off('lobbyCreated',handleLobbyCreated)
        }
    }, [socketService.socket])

    const handleLobbyCreated = () => {      
        navigate('/games')
    }
    const handleStartALobby = () => {
        localStorage.removeItem("lastGamePlayed")
        postAnalytics('host_clicked_create_lobby')
        if(handle) {
            handle.enter()
        } else {
            postAnalytics('error_handle_undefined')
        }
        socketService.emitSocketEvent('createLobby', {from,dateStartedPlaying: parseInt(getDateStartedPlaying()!)})
    }
    const handleGameClicked = (game: IGame) => {
        if(handle) {
            handle.enter()
        }
        postAnalytics('host_clicked_on_specific_game', {gameKey: game.key})
        navigate( "/" + game.path)
    }
    return (
        <GamesHiveBackground onGameClicked={handleGameClicked}>
            <div className={classes.explainerContainer}>
                    <a className={classes.supportTop} href='https://patreon.com/PlayCloud?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink'>
                        <img src={patreonIcon} className={classes.icon}/>

                            <div className={classes.textContainer}>
                                <div className={classes.question}>
                                    {t.enjoying}
                                </div>
                                <div className={classes.answer}>
                                    {t.supportUs} 

                                </div>
                            </div>
                </a>
               {/*  <ReportBugModal/> */}        
                <div className={ classes.titleContainer }>
                    <img src={ logoImg } className={ classes.logoImg } />
                    <div className={ classes.title }>
                        PlayCloud
                    </div>
                </div>
                <div className={ classes.gameDisplay }>
                    <div className={ classes.playerWrapper}>
                        <ReactPlayer
                            className='react-player fixed-bottom'
                            url= {`/videos/introductionVideo.mp4`}
                            width='100%'
                            height='100%'
                            playsinline
                            loop
                            muted
                            playing
                        />
                    </div>
                    <img src={ explainerImg } className={classes.explainerImg}/>
                </div>            
                <div className={ classes.explainer2 }>
                {t.explainer}
                </div>
                <div onClick= { handleStartALobby }  className={ classes.playButton}>
                 {t.button} <img src={invertedLogo} className={classes.invertedIcon}/>
                </div>
                <div className={ classes.explainer }>
                {t.title}
                </div>
                <Explainer />
                <a className={classes.credits} href={"/credits"}>
            Credits
        </a>
            </div>
        </GamesHiveBackground>

    )
  }
  
const BrowserExplainerContainer = ({ handle }: {handle: any}) => {
    const featureToggles = useContext(FeatureToggleContext);

    const navigate = useNavigate()
    const from = localStorage.getItem("from")
    const t = getTranslation("launch")

    const moveToBrowserController = () => {
        navigate('/browser?from=' + from)
    }

    const handlePlayInBrowser = () => {
        moveToBrowserController()
    }
    const handlePlayInApp = () => { 
        let appWindow = window.open(DEEP_LINK_ANDROID,"_blank");
        setTimeout( function () {if (appWindow) {
            appWindow.location =PLAY_STORE_URL;
                }
         },1000);
    }
    const handlePlayInAppIos= () => { 
        /*
        let appWindow = window.open(DEEP_LINK_ANDROID,"_blank");
        setTimeout( function () {if (appWindow) {
            appWindow.location =APP_STORE_URL;
                }
         },1000);
         */
         window.open(APP_STORE_URL,"_blank");

    }
    const ExplainerCarousel = () => (
       
            <Carousel
             renderIndicator={(
                clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
                isSelected: boolean
              )  => <li onClick={ clickHandler } className={classNames(classes.indicator,{[classes.active]: isSelected})}/>}
                preventMovementUntilSwipeScrollTolerance={ true}
                swipeScrollTolerance={ 40}
                
                swipeable
                showArrows={ false}
                showThumbs={ false}
                showStatus={ false}
            >
                    <div className={classes.parent}>
                        <ExplainerItem img={gameIllustrator} text={t.step1Carousel} />
                    </div>
                <div className={classes.parent}>
                        <ExplainerItem img={tv} text={t.step2Carousel} />
                    </div>
                <div className={classes.parent}>
                    <ExplainerItem img={step1} text={t.step3Carousel} />
                </div>


            </Carousel>
        )

        const isAppButtonAvailable = (isAndroid    &&  featureToggles.isAndroidMobileAppAvailble)||  ( isIOS &&  featureToggles.isIosMobileAppAvailable)
    return (
      <div className={classes.browserExplainerContainer}>

         <div className={ classes.titleContainerMobile }>
            <img src={ logoImg } className={ classes.logoImg } />
            <div className={ classes.title }>
                PlayCloud
            </div>
        </div>
        <div className={ classes.gameDisplayMobile }>
            <div className={ classes.playerWrapperMobile}>
                <ReactPlayer
                    className='react-player fixed-bottom'
                    url= {`/videos/introductionVideo.mp4`}
                    width='100%'
                    height='100%'
                    playsinline
                    muted
                    loop
                    playing
                />
            </div>
            <img src={ explainerImg } className={classes.explainerImgMobile}/>
        </div>  
        <div className={ classes.explainer }>
          {t.browserExplainer}
        </div>

        {
            isAndroid    &&  featureToggles.isAndroidMobileAppAvailble && 
            <a href={DEEP_LINK_ANDROID} onClick= { handlePlayInApp } className={ classes.startBtnApp}>
             <img src={ googlePlayIcon } className={classes.googlePlayIcon} /> {t.browserBtnDownload}
        </a>
        }
        
        {
            isIOS &&  featureToggles.isIosMobileAppAvailable &&  <a href={APP_STORE_URL} onClick= { handlePlayInAppIos } className={ classes.startBtnApp}>
            <img src={ appStoreIcon } className={classes.googlePlayIcon} /> {t.browserBtnDownload}
        </a>
        }
                {
            (featureToggles.isAbleToPlayBrowserAndroid && isAndroid ||
            featureToggles.isAbleToPlayBrowserIos && isIOS ) &&     <div onClick= { handlePlayInBrowser } className={isAppButtonAvailable ? classes.startBtn : classes.startBtnApp}>
            {t.browserBtn}
        </div>
    }
        <div>
            <div  className={classes.consoleExplainer}>
                <div className={classes.howDoes}>
                    {t.howDo}
                </div>

                <div className={classes.answer}>
                    {t.goTo} <div className={classes.blockLink}>www.play-cloud.com</div> {t.goTo2}
                </div>
            </div>
            <div  className={classes.consoleExplainerText}>
            </div>
            <div  className={classes.consoleExplainer}>
                <div className={classes.howDoes}>
                    {t.howDoes}
                </div>
            </div>
        </div>

        <div className={classes.carouselWrapper}>
            <ExplainerCarousel />
        </div>

      </div>
    )
  }
function LaunchContainer({ handle }: {handle: any}) { 
   useEffect(() => {
        postAnalytics("launch_page_shown", {link: window.location.href})
        if(localStorage.getItem("first_referrer") == null) {
            const referrerDomain = document.referrer === "" ? document.referrer : (document.referrer.includes('play-cloud') ? '' : document.referrer.split('/')[2])
            localStorage.setItem("first_referrer", referrerDomain)
        }
    }, []);



  return (
    <div className={ classes.wrapper }>
        <div  className= { classes.wrapper2 }>
            {
                ((window.matchMedia("(min-width: 768px)").matches && isMobile) || isBrowser)
                ? <ExplainerContainer handle={handle}/> : <BrowserExplainerContainer handle={handle}/>
            }
            <ArticleLinks/>
            <GameArticleLinks/>
        </div>
    </div>
  );
}

export default LaunchContainer;
