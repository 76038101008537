import axios from "axios"
import { FeatureToggles } from "../App"
import { CONSOLE_URL } from "../consts/config"
import IAdmin from "../interfaces/admin"
import IGame from "../interfaces/game"

export const getGamesRequest = async () : Promise<Array<IGame>> =>  {
    const response = await axios.get('/data/games', {baseURL: CONSOLE_URL})
    return response.data
}
export const postAnalytics = async (name: string, params?: any) : Promise<Array<IGame>> =>  {
    const response = await axios.post('/reportAnalytics', {name, params}, {baseURL: CONSOLE_URL})
    return response.data
}
export const getAdminDataRequest = async (admin: string, url : string) : Promise<IAdmin> =>  {
    const response = await axios.get('/data/server?admin=' + admin, {baseURL: url})
    return response.data
}

export const postLobbyLog = async (log: string, roomNumber:string)  =>  {
    await axios.post('/logToLobby', {log, roomNumber},{baseURL: axios.defaults?.baseURL?.includes('play-cloud.com')? `${axios.defaults.baseURL}/api` : axios.defaults.baseURL })
}


export const getSocketServerAddress = async () : Promise<{featureToggles: FeatureToggles,address: string}> =>  {
    const response = await axios.get('/getSocketServerAddress', {baseURL: CONSOLE_URL})
    return response.data
}