import React, { useContext, useEffect, useState } from 'react';
import classes from './admin.module.scss'
import { useQuery } from '../../hooks/useQuery';
import { getAdminDataRequest } from '../../httpRequests/consoleRequests';
import IAdmin, { ILobby } from '../../interfaces/admin';
import dayjs, { Dayjs } from 'dayjs';
import { getTranslation } from '../../utils/anyUtils';
import classNames from 'classnames';
import { CONSOLE_URL, CONSOLE_URL_EU, CONSOLE_URL_US } from '../../consts/config';
import utc from 'dayjs/plugin/utc.js'

dayjs.extend(utc)

enum EGameStatus {
  didntStart = 'didntStart',
  inprogress = 'inprogress',
  ended = 'ended'
}
export interface AdminDataVM  {
    onlinePlayers: {
      eu?: number,
      us?: number,
    }
    activeLobbys: {
      eu?: number,
      us?: number,
    },
    activeGames: {
      eu?: number,
      us?: number,
    },
    serverStatus: {
      eu: boolean,
      us: boolean
    }
    lobbyData: Array< ILobby>
    history: Array< ILobby>

}
function AdminContainer() {
  const [adminData, setAdminData] = useState<AdminDataVM>({serverStatus: {us: false, eu: false},onlinePlayers: {}, activeGames: {}, activeLobbys: {},lobbyData: [], history: []})
  const query = useQuery()
  useEffect(() => {
      // @ts-ignore
      getAllAdminData( query.admin)
  }, [])
   const getAllAdminData = async (admin : string) => {
    await getAdminData(admin,'eu',CONSOLE_URL_EU );
    await getAdminData(admin,'us',CONSOLE_URL_US );
   }
   
   const getAdminData = async (admin : string, region: string, url:string ) => {
    console.log("adminData", adminData)
    try {
      let adminDataUs  = await getAdminDataRequest(admin, url)
      setAdminData((currentAdminData) => { return{
        serverStatus: {
          ...currentAdminData.serverStatus,
          [region]: true,
        },
        onlinePlayers: {
          ...currentAdminData.onlinePlayers,
          [region]: adminDataUs.onlinePlayers,
        },
        activeLobbys:{
          ...currentAdminData.activeLobbys,
          [region]: adminDataUs.activeLobbys,
        },
        activeGames:{
          ...currentAdminData.activeGames,
          [region]: adminDataUs.activeGames,
        },
        lobbyData: [
          ...currentAdminData.lobbyData,
          ...adminDataUs.lobbyData.map((data: ILobby) => ({
            ...data,
            region: region
          }))
        ].sort((a,b) => dayjs(a.startTime) > dayjs(b.startTime) ? -1 : 1),
        history: [
          ...currentAdminData.history,
          ...adminDataUs.history.map((data: ILobby) => ({
            ...data,
            region: region
          }))
        ].sort((a,b) => dayjs(a.startTime) > dayjs(b.startTime) ? -1 : 1),
  
      }})
    } catch(e) {
      setAdminData((currentAdminData) => { return{
        ...currentAdminData,
        serverStatus: {
          ...currentAdminData.serverStatus,
          [region]: false,
        },
      }})
      return;
    }

   }

   const calculateDaysTag = (creationDate: Dayjs,date : Dayjs) => {
    if( creationDate.diff(dayjs(date), "seconds") < 60) {
      return dayjs(date).format("DD/MM/YY") + " ( " + creationDate.diff(dayjs(date), "seconds") + " s " + ")"
    }
    if( creationDate.diff(dayjs(date), "minutes") < 60) {
      return dayjs(date).format("DD/MM/YY") + " ( " +creationDate.diff(dayjs(date), "minutes") + " m " + ")"
    }
    if( creationDate.diff(dayjs(date), "hours") < 24) {
      return dayjs(date).format("DD/MM/YY")+ " ( " +creationDate.diff(dayjs(date), "hours") + " h " + ")"
    }
    return dayjs(date).format("DD/MM/YY") + " ( " +creationDate.diff(dayjs(date), "days") + " d " + ")"

   }

   if(!adminData) {
     return null
   }
   const Panel = ({title, stat, children}: {title:string, stat?: any, children?: any}) => {
    return (
     <div className={classes.panel}>
      <div className={classes.description}>
        {title} 
      </div>
      {
        children ? children : 
        <div className={classes.stat}>
          {stat}
        </div>
      }
  </div>)
   }
   const PanelStatRegional = ({title, stats, children}: {title:string, stats: {eu?: any, us?: any}, children?: any}) => {
    return (
     <div className={classes.panelStatRegional}>
      <div className={classes.description}>
        {title} 
      </div>
      <div>
        {
          ('eu' in stats) &&
          <div className={classNames(classes.stat,classes['eu'])}>
          <div className={classes.explainer}>
            EU
          </div>
          <div>
          {typeof stats.eu === "number" ? stats.eu : (!!stats.eu ? <div className={classes.online} />: <div className={classes.offline} />)}
          </div>
        </div>
        }
        {
          ('us' in stats) &&
          <div className={classNames(classes.stat,classes['us'])}>
          <div className={classes.explainer}>
            US
          </div>
          <div>
            {typeof stats.us === "number" ? stats.us : (!!stats.us ? <div className={classes.online} />: <div className={classes.offline} />)}
          </div>
        </div>
        }

        </div>
  </div>)
   }
   const PanelRow = ({children}: {children:any}) => {
    return (
     <div className={classes.panelRow}>
      {children}
    </div>)
   }
   const handleViewLogs = (roomNumber: string, region: string, history?: boolean) => {
     window.location.href = window.location.protocol + "//" + window.location.host + "/logs?admin=cheneli&roomNumber=" + roomNumber + (history ? "&history=true" : "") + "&region=" + region
  }
  const handleGameLogs = (roomNumber: string, region: string, index: number,history?: boolean) => {
    window.location.href = window.location.protocol + "//" + window.location.host + "/gameLogs?admin=cheneli&roomNumber=" + roomNumber + (history ? "&history=true" : "") + "&region=" + region + "&gameIndex=" + index.toString();
 }
  const getGameStatus = (startTime: Date | undefined, endTime: Date | undefined) => {
    if(!!startTime && !!endTime) {
      return EGameStatus.ended
    }
    if(!!startTime && !endTime) {
      return EGameStatus.inprogress
    }
    if(!startTime && !endTime) {
      return EGameStatus.didntStart
    }
    return EGameStatus.didntStart
  }

  return (
    <div className={ classes.wrapper}>
      <PanelRow>
      <PanelStatRegional title={"Server status"} stats={adminData.serverStatus}/>
      </PanelRow>
      <PanelRow>
      <PanelStatRegional title={"Active Lobbies"} stats={adminData.activeLobbys}/>
        <PanelStatRegional title={"Active Games"} stats={adminData.activeGames}/>
        <PanelStatRegional title={"Online Players"} stats={adminData.onlinePlayers}/>
      </PanelRow>
        <Panel title={"Lobbies"} stat={adminData.lobbyData.length > 0 ? undefined : "No lobbies exist"}>
          {
            adminData.lobbyData.length > 0 &&
            <div className={classes.lobbyContainer}>
            {
            adminData.lobbyData.map(lobby => (
              <div className={classNames(classes.lobbyData, classes[lobby.region])}>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Room Number 
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.roomNumber}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Players 
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.players.filter(player => !!player).length}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div style={{cursor: "pointer", color: "#90f0ff", fontWeight: "bold", textDecoration: "underline"}} onClick={ () =>  handleViewLogs(lobby.roomNumber, lobby.region) } className={classes.descriptionLobby}>
                    View logs
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Time active
                  </div>
                  <div className={classes.statLobby}>
                    {dayjs().diff(lobby.startTime, "minutes")} Minutes
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Country
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.country ? lobby.country : "-"}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Referrer
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.from}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    First play
                  </div>
                  <div className={classes.statLobby}>
                  {calculateDaysTag(dayjs(lobby.startTime).utc(),dayjs.unix(lobby.dateStartedPlaying).utc())}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Admin
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.admin ? lobby.admin : "-"}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Max players connected
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.maxConnectedPlayers}
                  </div>
                </div>
                {
                  !!lobby.gamesPlayed.length && 
                  <div className={classes.lobbyRow}>
                    {
                      lobby.gamesPlayed.map((gameData,gameIndex) => (
                        <div className={classNames(classes.gamesData,classes[getGameStatus(gameData.startTime, gameData.endTime).toString()]) }>
                          <div className={classes.gameName}>
                            { getTranslation("gamekeys" + gameData.game ).name }
                          </div>
                          <div className={classes.gameTime}>
                            <div>{ gameData.startTime ? `${dayjs(gameData.endTime).diff(gameData.startTime, "minutes")} Minutes` : "Not launched"}</div>
                            <div style={{cursor: "pointer",fontSize: '10px', color: "black", fontWeight: "bold", textDecoration: "underline"}} onClick={ () =>  handleGameLogs(lobby.roomNumber, lobby.region, gameIndex) } className={classes.descriptionLobby}>
                            View logs
                          </div>
                          </div>
                      </div>
                      ))
                    }
                  </div>

                }

              </div>
            ))
          }
        </div>
          }
      </Panel>
      <Panel title={"Lobbies Ended"} stat={adminData.history.length > 0 ? undefined : "No history found"}>
          {
            adminData.history.length > 0 &&
            <div className={classes.lobbyContainer}>
            {
            adminData.history.map(lobby => (
              <div className={classNames(classes.lobbyData, classes[lobby.region])}>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Room Number 
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.roomNumber}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div style={{cursor: "pointer", color: "#90f0ff", fontWeight: "bold", textDecoration: "underline"}} onClick={ () =>  handleViewLogs(lobby.roomNumber, lobby.region, true) } className={classes.descriptionLobby}>
                    View logs
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Start time
                  </div>
                  <div className={classes.statLobby}>
                    {dayjs(lobby.startTime).format("h:mm:ss A")}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Minutes of activity
                  </div>
                  <div className={classes.statLobby}>
                    {dayjs(lobby.endTime).diff(lobby.startTime, "minutes")} Minutes
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Country
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.country}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Referrer
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.from}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    First play
                  </div>
                  <div className={classes.statLobby}>
                  {calculateDaysTag(dayjs(lobby.startTime).utc(), dayjs.unix(lobby.dateStartedPlaying).utc())}
                  </div>
                </div>
                <div className={classes.lobbyRow}>
                  <div className={classes.descriptionLobby}>
                    Max players connected
                  </div>
                  <div className={classes.statLobby}>
                    {lobby.maxConnectedPlayers}
                  </div>
                </div>
                {
                  !!lobby.gamesPlayed.length && 
                  <div className={classes.lobbyRow}>
                    {
                      lobby.gamesPlayed.map((gameData,gameIndex) => (
                        <div className={classNames(classes.gamesData,classes[getGameStatus(gameData.startTime, gameData.endTime).toString()]) }>
                          <div className={classes.gameName}>
                            { getTranslation("gamekeys" + gameData.game ).name }
                          </div>
                          <div className={classes.gameTime}>
                            <div>{ gameData.startTime ? `${dayjs(gameData.endTime).diff(gameData.startTime, "minutes")} Minutes` : "Not launched"}</div>
                            <div style={{cursor: "pointer",fontSize: '10px', color: "black", fontWeight: "bold", textDecoration: "underline"}} onClick={ () =>  handleGameLogs(lobby.roomNumber, lobby.region, gameIndex, true) } className={classes.descriptionLobby}>
                            View logs
                          </div>
                          </div>
                      </div>
                      ))
                    }
                  </div>

                }

              </div>
            ))
          }
        </div>
          }
      </Panel>
  </div>  
    
  );
}

export default AdminContainer;
