import classNames from 'classnames';
import classes from './introduction5.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function Introduction2Container( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - PC games to play with friends"}>
      <div className={classes.wrapper}>
      <h1>Welcome to PlayCloud</h1>
  <p>The future of gaming that allows you to use your smartphone as a controller. With PlayCloud, you can enjoy a wide selection of <strong>games that can be played with friends on your PC, all for free</strong>.</p>
  <p>Gone are the days of limited controllers and expensive equipment. PlayCloud brings a new level of convenience and excitement to your gaming experience. Gather your friends and family together in the same room and enjoy endless hours of fun and entertainment with these <strong>free PC games to play with friends</strong>.</p>
  <p>Our innovative system connects your phone directly to our cloud gaming console, giving you a seamless experience like no other. Simply download the PlayCloud app, connect to the console, and start playing your favorite <strong>games that can be played with friends</strong> right away. PlayCloud offers a variety of games, including co-op adventures and competitive multiplayer titles.</p>
  <p>At PlayCloud, we're committed to creating a seamless and enjoyable gaming experience. Our cloud gaming console is constantly updated with new games and features, ensuring that you have access to the latest and greatest <strong>free PC games to play with friends</strong>.</p>
  <p>One of the key features of PlayCloud is that it allows you to use your smartphone as a controller. This means that you can have up to four players on one console, without any additional costs or complicated setups. Our system is perfect for parties, family gatherings, or even just a night in with friends.</p>
  <p>With PlayCloud, there's no need to buy expensive equipment or worry about setting up complicated systems. Our cloud gaming console is easy to use and requires no additional hardware. Simply download the app and start playing these <strong>fun games to play with friends on your PC</strong>.</p>
  <p>Join the PlayCloud community today and experience the future of gaming. It's free, it's fun, and it's waiting for you. Enjoy these <strong>games that can be played with friends</strong> and have a blast!</p>
</div>
      </ExplainerWrapper>


  );
}

export default Introduction2Container;
