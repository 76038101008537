import React, { useContext } from 'react';
import classes from './logo.module.scss'
import logoImg from '../../assets/images/logoImg.png';
import classNames from 'classnames';

interface IProps {
  className?: string
}
function Logo({ className }: IProps) {

  return (
    <img src={ logoImg } className={ classNames(classes.logoImg, className) } />
  );
}

export default Logo;
