import classNames from 'classnames';
import classes from './fourPlayersIntroduction.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function FourPlayersIntroduction( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"Local Multiplayer Games for 4 Players? PlayCloud Makes it Easy!"}>
              <div className={classes.wrapper}>
              <h2>Forget Physical Consoles, PlayCloud is All About Your Phone!</h2>
    <p>Tangled controllers and limited couch space cramping your local multiplayer style? PlayCloud is here to revolutionize your gaming experience. Ditch the bulky hardware and endless controller searches! PlayCloud transforms your smartphone into a powerful controller. Simply connect to the PlayCloud app and you're ready to rumble!</p>
    <h2>Plug, Play, Party! Effortless Local Multiplayer for Four</h2>
    <p>Gone are the days of complex setups and waiting. PlayCloud seamlessly connects to your TV, allowing you to jump into epic 4-player battles with friends and family in seconds. Get ready for an effortless transition from setup to pure gaming fun!</p>
    <h2>The Perfect Platform for Your Next Local Multiplayer Bash</h2>
    <h2>Party Games Galore</h2>
    <p>Unleash the laughter with hilarious party games, Prepare for friendly competition and side-splitting chaos that will have you reminiscing for days!</p>
    <h2>Platforming Adventures Await</h2>
    <h2>Sports & Racing Mania</h2>
    <p>Rev up your engines and get ready to compete in sports and racing games similar to Mario Cart racing, These titles offer both cooperative and competitive thrills for all player types. Whether you're a seasoned athlete or a casual racer, PlayCloud has something for everyone.</p>
    <h2>Benefits Beyond Convenience: Why PlayCloud is a Game Changer</h2>
    <p>PlayCloud goes beyond just being convenient. Here's why it's the ultimate local multiplayer platform:</p>
    <ul>
      <li>**Cost-Effective:** Ditch the expensive consoles and controllers. PlayCloud offers a more affordable way to enjoy local multiplayer games.</li>
      <li>**Always Up-to-Date:** Cloud-based gaming ensures you always have access to the latest games and updates. No more dusty cartridges or bulky discs!</li>
      <li>**Massive Game Library:** PlayCloud boasts a vast library of local multiplayer games, so there's always something new to discover. Explore endless possibilities for endless fun.</li>
    </ul>
    <h2>Reignite the Local Multiplayer Magic with PlayCloud</h2>
    <p>Looking to recapture the joy of social gaming with friends and family? Look no further than PlayCloud. With its streamlined setup, 4-player support, and ever-expanding game library, PlayCloud is the perfect platform to create unforgettable local multiplayer memories. Download the PlayCloud app today and unleash a new era of local multiplayer fun!</p>

</div>
      </ExplainerWrapper>


  );
}

export default FourPlayersIntroduction;
