import React, { useContext } from 'react';
import classes from './explainer.module.scss'
import enlargeIcon from '../../assets/images/enlargeIcon.png'
import minifyIcon from '../../assets/images/minifyIcon.png'
import step1 from '../../../assets/images/step1.png';
import qrStep from '../../../assets/images/qrstep.png';
import tv from '../../../assets/images/tv.png';
import gameIllustrator from '../../../assets/images/game-illustrator.png';
import { translations } from '../../../i18n/config';
import { getTranslation } from '../../../utils/anyUtils';
import ExplainerItem from '../../../components/explainerItem';

interface IProps {
  className?: string
  handle?: any
}
function GameTopLayer({ handle, className }: IProps) {
  const t = getTranslation("launch")

  return (
    <div className={ classes.container }>
      <ExplainerItem img={qrStep} >
        <div className={ classes.description }>
          {t.step1}
        </div>
        <div className={ classes.link }>
          https://www.play-cloud.com
        </div>
        <div className={ classes.illustrationContainer }>
          <img src={ qrStep } className={ classes.stepImg} />
        </div>
      </ExplainerItem>
      <ExplainerItem img={step1} text={t.step2} />
      <ExplainerItem img={gameIllustrator} text={t.step3} />
      <ExplainerItem img={tv} text={t.step4} />
  </div>
  );
}

export default GameTopLayer;
