import classNames from 'classnames';
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ReactPlayer from 'react-player';
import explainerImg from '../../assets/images/explainerImg.png'
import personIcon from './../../assets/images/personIcon.png'
import gyroIcon from './../../assets/images/MOVEMENTICON (1).png'
import { useNavigate } from 'react-router-dom';
import { FeatureToggleContext, IPlayer, LobbyContext, SocketContext } from '../../App';
import GameTopLayer from '../../components/gameTopLayer';
import { getGamesRequest } from '../../httpRequests/consoleRequests';
import IGame from '../../interfaces/game';
import socketService from '../../services/socket.service';
import googlePlayIcon2 from '../../assets/images/googlePlayIcon2.png'
import appStoreIcon from '../../assets/images/appStoreIcon.png'
import classes from './games.module.scss'
import Logo from '../../components/logo/logo';
import QRCode from 'react-qr-code';
import AddPeopleExplainer from '../../components/addPeopleExplainer';
import { getLanguage, getTranslation } from '../../utils/anyUtils';
import { reportEvent } from '../../services/analytics.service';
import ReportBugModal from '../../components/reportBugModal';
import dayjs from 'dayjs';

var audio = new Audio('./../../sounds/buttonTick.mp3');
var notification = new Audio('./../../sounds/notificationSound2.mp3');


function GamesContainer( { handle }: {handle: any}) {
  const [lastConnectedPlayer, setLastConnectedPlayer] =  useState<IPlayer | null>(null)
  const [selectedGameIndex, setSelectedGameIndex] = useState<number>(0)
  const [selectedRowIndex, setSelectedGameRowIndex] = useState<number>(0)
  const [gameToRecommand, setGameToRecommand] = useState<IGame| undefined>(    
    undefined)
  const selectedIndexRef = useRef<number>(0)
  const selectedRowIndexRef = useRef<number>(0)
  const lastAdmin = useRef<string>("")
  const gamesRef = useRef<Record<string, HTMLDivElement  | null>>({})
  const [availableGames, setAvailableGames] = useState<{name: string, games: Array<IGame>}[]>([])
  const lobbyContext = useContext(LobbyContext);
  const featureToggles = useContext(FeatureToggleContext);
  const navigate = useNavigate()
  const language = getLanguage()
  const t = getTranslation("games")
  // @ts-ignore
  const rolloutStorage = localStorage.getItem('rollout')
  const rollout = rolloutStorage == null ? 'production' : rolloutStorage
  const isLoading = availableGames.length === 0
  const timeoutLobbyChange = useRef<NodeJS.Timeout>()
  let playersBefore  = useRef<IPlayer[]>(lobbyContext.players)

  useEffect(() => {
    if(lobbyContext) {
      setLobbyIdleTimeout()
    }
  }, [lobbyContext])
  useEffect(() => {
    if(!isLoading) {
      const lastGamePlayed = localStorage.getItem("lastGamePlayed")
      if(featureToggles.recommandOtherPlatforms && lastGamePlayed) {
        setTimeout(() => {
          recommandGame(lastGamePlayed)
        }, 1000) 
      }
      socketService.socket.emit("logToLobby", {message: "Finished loading Games for the lobby"})
    }
  }, [availableGames,featureToggles])
  useEffect(() => {
    if(gamesRef.current[selectedRowIndex.toString() + selectedGameIndex.toString()] && gamesRef.current[selectedRowIndex.toString() + selectedGameIndex.toString()]!.scrollIntoView != undefined) {
      gamesRef.current[selectedRowIndex.toString() + selectedGameIndex.toString()]!.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'})
    }
  }, [selectedGameIndex, selectedRowIndex])
  useEffect(() => {
    if(!socketService.socket) {
      window.location.href = window.location.protocol + "//" + window.location.host
    }
    window.onpopstate = handleBackButton
    socketService.socket.emit("logToLobby", {message: "Loading games for the lobby"})
    if(!lobbyContext.roomNumber) {
      window.location.href = window.location.protocol + "//" + window.location.host
      return;
    }
    getAvailableGames()
    return () => {
      if(timeoutLobbyChange) {
        clearTimeout(timeoutLobbyChange.current)
      }
      socketService.socket.off('playerMoved', handlePlayerMoved);
      socketService.socket.off('select', handleStartALobby);
    }
  }, [])
  useEffect(() => {
    if(lobbyContext.players && lobbyContext.players.filter(p => !!p && p.id !== null).length > 0 ){
 //     orderGamesBasedOnNOfPlayers(availableGames.find(category => category.name === 'mostPopular')?.games!, lobbyContext.players.filter(p => !!p && p.id !== null).length )
       setRowBasedOnNumberOfPlayers(lobbyContext.players.filter(p => !!p && p.id !== null).length)
      if(availableGames.length > 0 && lastAdmin.current !== lobbyContext.admin) {
        lastAdmin.current = lobbyContext.admin!
        socketService.socket.emit("scrollingThrouGames", {currentGameKey: availableGames[selectedRowIndexRef.current].games[selectedIndexRef.current]})
      }
      
     
      if(lobbyContext.players[0] != null && !!lobbyContext.players[0].from) {
        const fromFromLocalstorage = localStorage.getItem("from")
        if(localStorage.getItem("firstPlayerLogged") != "true" && fromFromLocalstorage?.includes('organic')) {
          if (localStorage.getItem("from") == null) {
            localStorage.setItem("firstPlayerLogged", "true")

            localStorage.setItem("from", lobbyContext.players[0].from!)   
          }
        }
      }
      

    }
    let connectedPlayer = lobbyContext.players.find(p => p!= null && playersBefore.current.findIndex(p2 => p2 != null && p2.id === p.id) === -1)
    if(connectedPlayer) {
      setLastConnectedPlayer(connectedPlayer)
      notification.play();
      setTimeout(() => {
        setLastConnectedPlayer(null)
      }, 2000)
    }
    playersBefore.current = lobbyContext.players
    
  }, [availableGames, lobbyContext.players, lastAdmin.current])

  useEffect(() => {
    if(availableGames.length > 0) {
      socketService.socket.emit("scrollingThrouGames", {currentGameKey: availableGames[selectedRowIndexRef.current].games[selectedIndexRef.current]})
    }

  }, [availableGames,selectedRowIndexRef.current, selectedIndexRef.current])
  useEffect(() => {
    if(availableGames.length <= 0) {
      return
    } 
    socketService.socket.on('playerMoved', handlePlayerMoved);
    socketService.socket.on('lobbyDisconnected', handleLobbyDeleted);
    socketService.socket.on('select', handleStartALobby);
    return () => {
      socketService.socket.off('playerMoved', handlePlayerMoved);
      socketService.socket.off('select', handleStartALobby);
    }
  }, [availableGames])
  const fontSizeMap = {
    0: 35,
    5: 26,
    8: 20,
  }
  /*
  const orderGamesBasedOnNOfPlayers = ( data: Array<IGame>, nOfPlayers: number) => {
    setSelectedGameIndex(0)
    console.log("orderGamesBasedOnNOfPlayers")
    if(nOfPlayers <= 1) {
      const categorizeGames = [
        {name: 'singlePlayer', games: data.filter(game => game.minNumberOfPlayers === 1)},
        {name: 'mostPopular', games: data},
        {name: 'gyroSupported', games: data.filter(game => game.gyroSupported)},
        {name: 'overfour', games: data.filter(game => game.maxNumberOfPlayers > 4)},
      ]
      setAvailableGames(categorizeGames)
    }
    if(nOfPlayers > 1 && nOfPlayers < 4) {
      const categorizeGames = [
        {name: 'mostPopular', games: data},
        {name: 'gyroSupported', games: data.filter(game => game.gyroSupported)},
        {name: 'overfour', games: data.filter(game => game.maxNumberOfPlayers > 4)},
        {name: 'singlePlayer', games: data.filter(game => game.minNumberOfPlayers === 1)},
      ]
      setAvailableGames(categorizeGames)
    }
    if(nOfPlayers >= 4) {
      const categorizeGames = [
        {name: 'overfour', games: data.filter(game => game.maxNumberOfPlayers > 4)},
        {name: 'mostPopular', games: data},
        {name: 'gyroSupported', games: data.filter(game => game.gyroSupported)},
        {name: 'singlePlayer', games: data.filter(game => game.minNumberOfPlayers === 1)},
      ]
      setAvailableGames(categorizeGames)
    }
  }
  */
   
  const setRowBasedOnNumberOfPlayers = (nOfPlayers: number) => {
    setSelectedGameIndex(0)
    if(nOfPlayers <= 1) {
      const  singlePlayerIndex= availableGames.findIndex(category => category.name === "singlePlayer")
      if(singlePlayerIndex === -1)  {
        setSelectedGameRowIndex(0)
      } else {
        setSelectedGameRowIndex(singlePlayerIndex)
      }

    }
    if(nOfPlayers > 1 && nOfPlayers <= 4) {
      const  mostPopularIndex= availableGames.findIndex(category => category.name === "mostPopular")
      if(mostPopularIndex === -1)  {
        setSelectedGameRowIndex(0)
      } else {
        setSelectedGameRowIndex(mostPopularIndex)
      }
    }
    if(nOfPlayers > 4) {
      const  overFourIndex= availableGames.findIndex(category => category.name === "overfour")
      if(overFourIndex === -1)  {
        setSelectedGameRowIndex(0)
      } else {
        setSelectedGameRowIndex(overFourIndex)
      }
    }
  }
  
  const getFontSize =(text: string, map: object) : number =>   {
    let fontSize = 32
    Object.entries(map).forEach(([key, value]) =>{
      if(parseInt(key) <= text.length) {
        fontSize = value
      
      }
    })
    return fontSize;
  }
  const handleLobbyDeleted = () => {
    window.location.href = '/'
  }
  const setLobbyIdleTimeout = () => {
    if(timeoutLobbyChange) {
      clearTimeout(timeoutLobbyChange.current)
    }
    timeoutLobbyChange.current = setTimeout(handleLobbyIdle, 1000 * 60 * 20)
  }
  const handleLobbyIdle = () => {
    socketService.socket.emit('closeLobby')
  }
  const handleBackButton = () => {
    socketService.socket.emit("logToLobby", {message: "Host clicked back button"})
  }
  const recommandGame = (gameKey: string) => {
    let foundGame :IGame | undefined
    
    availableGames.forEach(category => {
      foundGame = category.games.find((game: IGame) => game.key === gameKey)
      if(foundGame) {
        return;
      }
    })
    
    if(foundGame && foundGame.otherPlatforms) {
   //   setGameToRecommand(foundGame)
   /*
      setTimeout(() => {
        localStorage.removeItem('lastGamePlayed')
        setGameToRecommand(undefined)
      }, 15000)
      */
    }
  }
  const getAvailableGames = async () => {
    const data : Array<IGame> = await getGamesRequest()
    const filteredGames = data.filter(game => rollout === "testing" ? true : game.rollout === 'production')
    const categorizeGames = [
      {name: 'mostPopular', games: filteredGames},
      {name: 'singlePlayer', games: filteredGames.filter(game => game.minNumberOfPlayers === 1)},
      {name: 'gyroSupported', games: filteredGames.filter(game => game.gyroSupported)},
      {name: 'overfour', games: filteredGames.filter(game => game.maxNumberOfPlayers > 4)},
    ]
    setAvailableGames(categorizeGames)
    setRowBasedOnNumberOfPlayers(0)
  }
  const handlePlayerMoved = (data: {position: {x:number, y:number}}) => {
    setLobbyIdleTimeout()
    const { position } = data
    if(position.x === -1) {
      socketService.socket.emit("logToLobby", {message: "Host scrolling through games"})
      audio.play();
      setSelectedGameIndex((prev: number) => {
        if(prev -1 < 0 ){
          selectedIndexRef.current = availableGames[selectedRowIndexRef.current].games.length - 1

          return availableGames[selectedRowIndexRef.current].games.length - 1
        } else {
          selectedIndexRef.current = prev - 1
          return prev - 1
        }
    })
      return
    }
    if(position.x === 1) {
      socketService.socket.emit("logToLobby", {message: "Host scrolling through games"})
      audio.play();
      setSelectedGameIndex((prev: number) => {
        if(prev + 1 >= availableGames[selectedRowIndexRef.current].games.length ){
          selectedIndexRef.current = 0;
          return 0
        } else {
          selectedIndexRef.current = prev + 1;
          return prev + 1
        }
    })
      return
    }

    if(position.y === -1) {
      audio.play();
      setSelectedGameIndex(0)
      selectedIndexRef.current = 0;
      setSelectedGameRowIndex((prev: number) => {
        if(prev + 1 >= availableGames.length ){
          selectedRowIndexRef.current = 0;
          return 0
        } else {
          selectedRowIndexRef.current = prev + 1;
          return prev + 1
        }
    })
      return
    }
    if(position.y === 1) {
      audio.play();
      setSelectedGameIndex(0)
      selectedIndexRef.current = 0;
      setSelectedGameRowIndex((prev: number) => {
        if(prev -1 < 0 ){
          selectedRowIndexRef.current = availableGames.length - 1
  
          return availableGames.length - 1
        } else {
          selectedRowIndexRef.current = prev - 1
          return prev - 1
        }
    })
      return
    }
  }

  const from = localStorage.getItem("from")
  const transformedFrom = from ? from.replace("_qr_scan", "") : from 
  const handleStartALobby = useCallback(() => {
    const selectedGame = availableGames[selectedRowIndexRef.current].games[selectedIndexRef.current]
    socketService.socket.emit("logToLobby", {message: "Host selected a game : " + selectedGame.key})

    reportEvent('host_game_selected')
    localStorage.setItem("loadingImage", selectedGame.loadingImgUrl)
    localStorage.setItem("maxNOfPlayers", availableGames[selectedRowIndexRef.current].games[selectedIndexRef.current].maxNumberOfPlayers.toString())
    socketService.emitSocketEvent("gameLaunched", {roomNumber: lobbyContext.roomNumber, gameKey: selectedGame.key})
    if(lobbyContext.roomNumber) {
      navigate('/game?roomNumber=' + lobbyContext.roomNumber + "&from=" + from +"&gameKey=" + selectedGame.key +"&host=" + socketService.address + "&language=" + language )
    }
  }, [availableGames, selectedGameIndex])


  const qrUrl = window.location.origin + "/download?roomNumber=" + lobbyContext.roomNumber! + "&from=" + transformedFrom + "_qr_scan" + "&language=" + language

  const selectedGame = availableGames[selectedRowIndexRef.current]?.games[selectedIndexRef.current]
  const categoriesTranslations = getTranslation("categories")
  const recommandationsTranslations = getTranslation("recommandations")

  const gameTranslations = getTranslation("gamekeys" + selectedGame?.key )
  return (
    <div className={ classes.wrapper }>
      <div className={classNames(classes.connectedPlayerContainer, {[classes.shown]: !!lastConnectedPlayer})}>
      {lastConnectedPlayer && 
      <>
                <div className={classes.circle} style={{fontSize: getFontSize(lastConnectedPlayer.name, fontSizeMap)}}> {lastConnectedPlayer.name}</div>
             <div className={classes.explain}> {t.hasConnected}</div>
      </>}
        

        </div>
       {
          isLoading &&
        <div className={ classes.logoWrapper }>
          <Logo/>
        </div>
        }
      {
        lobbyContext.roomNumber && availableGames.length > 0  && 
        <div className={ classes.container }>
          <GameTopLayer handle={ handle } />
          <>
          <div className={ classes.gamesWrapper}>
      <QRCode className={ classes.qr } size={200} value={ qrUrl }/>
      <div className={ classNames(classes.gameRecommandation, {[classes.shown]: !!gameToRecommand})}>
        {
          gameToRecommand && 
          <>
          <div className={classes.recommndationTitle}>
            {recommandationsTranslations.enjoyed} {getTranslation("gamekeys" + gameToRecommand.key ).name} ?
          </div>
          <div className={classes.other}>
            <div className={classes.availableFor}>
              {recommandationsTranslations.alsoAvailable } {" "} 
              <strong>
              {gameToRecommand.otherPlatforms?.android && gameToRecommand.otherPlatforms.ios ? `${recommandationsTranslations.googlePlayStore}, ${recommandationsTranslations.appStore}.` : (recommandationsTranslations.googlePlayStore ? `${recommandationsTranslations.googlePlayStore}.` : `${recommandationsTranslations.appStore}`)}
              </strong>
            </div>
            {
              featureToggles.recommandOtherPlatforms && gameToRecommand.otherPlatforms &&
              <div className={classes.platformRecommndation}>
                <img src={gameToRecommand.otherPlatforms?.iconUrl} className={classes.platformIcon}/>
                <div className={classes.appName}>
                  {getTranslation("gamekeys" + gameToRecommand.key ).name}
                </div>
                {
                  gameToRecommand.otherPlatforms.android &&
                  <img src={googlePlayIcon2} className={classes.googlePlayIcon2} />
                }
                {
                  gameToRecommand.otherPlatforms.ios &&
                  <img src={appStoreIcon} className={classes.googlePlayIcon2} />
                }
              </div>
            }
          </div>
          </>
        }

        </div>
      <div className={ classes.gamesContainer }>
        {
          selectedGame &&
          <div className={ classes.selectedGameDisplay }>

          <div className={ classes.selectedGameData }>
            <div className={ classes.details }>
              <div className={ classes.gameName }>
                {selectedGame.name}
              </div>

              <div className={ classes.participants }>
                <img src={ personIcon } className={ classes.personIcon }/>
                <div>
                  {selectedGame.minNumberOfPlayers} - {selectedGame.maxNumberOfPlayers}
                </div>
              </div>
              {
                selectedGame.gyroSupported && 
                <div className={ classes.participants }>
                  <img src={ gyroIcon } className={ classes.gyroIcon }/>
                  <div>
                    {t.gyroSupport}
                  </div>
                </div>
              }

              <div className={ classes.description }>
                {gameTranslations ? gameTranslations.description : undefined}
              </div>
            </div>

            <div className={ classes.btnWrapper }>
              <div className={ classes.startBtn }>
                {t.playnow}
                <div className={ classes.howTo }>
                  {t.howtoplay}
              </div>
              </div>
            </div>
          </div>

          <div className={ classes.gameDisplay }>
            <div className={ classes.gradient} />
            <div className='player-wrapper'>
            <ReactPlayer
            playsinline
                className='react-player fixed-bottom'
                url= {`/videos/${selectedGame.key || 'testgamelocal'}.mp4`}
                width='100%'
                height='100%'
                loop
                playing
                />
        </div>
          </div>
        </div>
        }
      <div className={ classes.bottomGradient} />
      </div>
      <div className={classes.categoriesWrapper}>

      {
        availableGames.map(({name, games}, rowIndex) => (
          <div className={classes.categoryLine}>
            <div className={classes.categoryName}>{categoriesTranslations[name]}</div>
            <div className={ classes.gamesLineWrapper} > 
              <div className={ classes.gamesLine} > 
                {
                  games.map((game,index) =>
                  <div
                  ref={ref => { gamesRef.current[(rowIndex).toString() + index.toString()] = ref;}} 
                  className={ classNames(classes.game, {[classes.selected]: selectedGameIndex === index && selectedRowIndex === rowIndex})}
                  >
                    {
                      !!game.firstReleaseDate && dayjs().diff(dayjs(game.firstReleaseDate), 'days') < 30 && 
                      <div className={classes.ribbon}>
                        <div className={classes.text}>
                          {t.new}
                        </div>
                      </div>
                    }

                    <img className={classes.img} src={game.imgUrl} />
                    </div>)
                }
                </div>

            </div>
          </div>

        ))
      }
      </div>


      </div>
            {   
            !((lobbyContext && lobbyContext.players && lobbyContext.players.filter(p => !!p).length) > 0) &&
            <AddPeopleExplainer 
              qrUrl={qrUrl}
               roomNumber={ lobbyContext.roomNumber }/>
            }
          </>

        </div>
      }
    </div>
  );
}

export default GamesContainer;
