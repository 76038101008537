import classNames from 'classnames';
import classes from './introductionjackbox.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function IntroductionJackboxContainer( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - The free Jackbox TV alternative"}>
      <div className={classes.wrapper}>
      <h2>Looking for a free and fun <strong>Jackbox TV alternative?</strong></h2>
      <p>PlayCloud is the perfect choice! Our virtual gaming console lets you use your phone as a controller to play games with friends in the same room, with support for up to 8 people at the same time.</p>
      <p>With PlayCloud, Like in Jackbox TV, you can enjoy a variety of fun and interactive games, including party games and cooperative gameplay, all without the need for additional hardware. Best of all, PlayCloud is completely free to use.</p>
      <p>Our platform is designed to be easy to use, so you can start gaming with your friends in no time. Whether you want to create a room and play with your friends, PlayCloud has got you covered.</p>
      <p>So why wait? Try PlayCloud today and discover the ultimate <strong>Jackbox TV alternative</strong>. Connect your smartphone as a controller and start playing games with your friends right away, all for free!</p>
  </div>
      </ExplainerWrapper>


  );
}

export default IntroductionJackboxContainer;
