

import React, { ReactNode, useEffect } from 'react';
import classes from './explainerItem.module.scss'

const ExplainerItem = ({img, children, text}: {img: string, children?: ReactNode, text?: string}) => {
    return (
        <div className={classes.parent}>
            <div className={ classes.step }>
                {
                    children ? 
                    children :             <div className={ classes.description }>
                    {text}
                    </div>
                }

                <div className={ classes.illustrationContainer }>
                <img src={ img } className={ classes.stepImg} />
            </div>
            </div>
        </div>

    )
  }

  export default ExplainerItem