import classNames from 'classnames';
import classes from './credits.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function FourPlayersIntroduction( ) {
  const handleClick = () => { window.location.href = '/'}
  const credits = [
    {
      name: "Quaternius",
      description: "Platforms, Characters",
      modifications: "Some elements were modified",
      link: "https://quaternius.com/packs/ultimateplatformer.html"
    },
    {
      name: "Yutaka",
      description: "Crystal",
      modifications: "None",
      link: "https://sketchfab.com/3d-models/toon-style-crystal-1f24004310a14ff29710ebc58a349371"
    },
    {
      name: "eduterrataca",
      description: "Sword",
      modifications: "None",
      link: "https://sketchfab.com/3d-models/001-cartoon-sword-fa3f163a92ec47b5bed9ec8cfa0dae20"
    },
    {
      name: "3d.rina",
      description: "Platform",
      modifications: "None",
      link: "https://assetstore.unity.com/packages/3d/props/handpainted-turntable-platforms-66599"
    },
  ]
    return (
      <div className={classes.wrapper}>
        Thank you for all of those free assets creators for making PlayCloud possible
        <div className={classes.columns}>
            <div className={classes.column}>
              name
            </div>
            <div className={classes.column}>
              asset type
            </div>
            <div className={classes.column}>
              modifications
            </div>
            <div className={classes.column}>
              link
            </div>
          </div>
          <div className={classes.credits}>
          {
          credits.map(credit => (<div className={classes.credit}>
          <div className={classes.creditCatergory}>
            {credit.name}
            </div>
            <div className={classes.creditCatergory}>
            {credit.description}
            </div>
            <div className={classes.creditCatergory}>
            {credit.modifications}
            </div>
            <div className={classes.creditCatergory}>
              <a href={credit.link}>
              link to assets
              </a>
             </div>

          </div>))
          }
          </div>

      </div>

  );
}

export default FourPlayersIntroduction;
