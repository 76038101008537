import {useEffect, useState} from 'react';
function preloadImage (src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = function() {
        resolve(img)
      }
      img.onerror = img.onabort = function() {
        reject(src)
      }
      img.src = src
    })
  }
  function myPromise(timeout: number, callback: any) {
    return new Promise((resolve, reject) => {
        // Set up the timeout
        const timer = setTimeout(() => {
            reject(new Error(`Promise timed out after ${timeout} ms`));
        }, timeout);

        // Set up the real work
        callback(
            (value: any) => {
                clearTimeout(timer);
                resolve(value);
            },
            (error: any) => {
                clearTimeout(timer);
                reject(error);
            }
        );
    });
}
function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
  export default function useImagePreloader(imageList: any[]) {
    const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)

    useEffect(() => {
      async function effect() {
        const imagesPromiseList: Promise<any>[] = []
        for (const i of imageList) {
          imagesPromiseList.push(preloadImage(i))
        }
        try {
          await myPromise(2000, async () => {
            await Promise.all(imagesPromiseList)
            setImagesPreloaded(true)
          });
        } catch(e) {
        }finally {
          setImagesPreloaded(true)
        }
      }
  
      effect()
  
      return () => {
      }
    }, [imageList])
  
    return { imagesPreloaded }
  }