import classNames from 'classnames';
import classes from './localmultiplayerintroduction.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function IntroductionJackbox2Container( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - Local Multiplayer gaming console"}>
                      <div className={classes.wrapper}>

    <section >
      <h2>Unleash the Fun Together: Local Multiplayer Games on Your Phone!</h2>
      <p>Remember the days of crowded living rooms, laughter echoing between friendly competition, and the joy of shared experiences? PlayCloud brings back that magic for a modern age, allowing you to experience the thrill of local multiplayer games without the need for bulky consoles or expensive controllers.</p>
    </section>
    <section >
      <h2>Embrace the Simplicity:</h2>
      <ul>
        <li>
          <h3>No Consoles, No Problem:</h3>
          <p>Ditch the need for expensive hardware. PlayCloud leverages the power of your smartphones and transforms them into intuitive controllers using their gyroscopes and touch screens. This makes PlayCloud universally accessible to anyone with a smartphone.</p>
        </li>
        <li>
          <h3>Plug-and-Play Simplicity:</h3>
          <p>Forget complex setups and lengthy downloads. Connect your computer and phones, browse our diverse library of games, and be ready to play in minutes. Get straight into the action and start creating lasting memories with friends and family.</p>
        </li>
      </ul>
      <h2>Dive into a World of Fun:</h2>
      <ul>
        <li>
          <h3>A Game for Every Player:</h3>
          <p>Explore our extensive collection of local multiplayer games catering to all ages, interests, and skill levels. From fast-paced action adventures and strategic battles to engaging party favorites, PlayCloud offers something for everyone to enjoy.</p>
        </li>
        <li>
          <h3>Competitive or Cooperative:</h3>
          <p>Whether you crave the adrenaline rush of competition or the camaraderie of cooperative play, PlayCloud has you covered. Choose your adventure and bond with your loved ones through shared experiences.</p>
        </li>
      </ul>
      <h2>Beyond the Screen:</h2>
      <ul>
        <li>
          <h3>Reconnect with Friends and Family:</h3>
          <p>In a world dominated by individual screens, PlayCloud encourages real-life interaction and connection. Step away from individual devices and create lasting memories through the shared joy of local multiplayer gaming.</p>
        </li>
        <li>
          <h3>More Than Just Games:</h3>
          <p>PlayCloud is more than just a gaming platform. It's a gateway to fostering connections, building memories, and experiencing the fun of social interaction in the digital age.</p>
        </li>
      </ul>
    </section>
    <section>
      <h2>Ready to rediscover the magic of local multiplayer games?</h2>
    </section>
    </div>
      </ExplainerWrapper>


  );
}

export default IntroductionJackbox2Container;
